.stats-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: none;
  }
  
  .stats-container {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }
  
  .stats-wrapper .grid-item-container {
    border: none; /* Removes the border only when inside stats-wrapper */
  }
  .stats-specific .grid-item-container {
    border: none; /* Removes the border only for stats-specific context */
  }

  .stats-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .stats-value {
    font-size: 1rem;
    font-weight: bold;
    @media (max-width: 640px) {
        font-size: 0.7rem;
    }
  }
  
  .stats-type {
    font-size: 1rem;
    text-align: center;
    @media (max-width: 640px) {
        font-size: 0.7rem;
    }
  }
  
  .stats-chart {
    display: flex;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  
  .single-bar {
    display: flex;
  }
  
  .chart-fill {
    height: 100%;
    transition: width 0.3s ease;
  }
  
  .away-fill {
    background-color: #ac7762;
  }
  
  .home-fill {
    background-color: #00e1ff;
  }