.nav-wrapper {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    color: goldenrod;
    /* New Stuff */
    flex: 1;
    align-self: flex-start;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .nav-logo {
    padding: 0.5rem 1rem;
    color: goldenrod;
  }

  .nav-logo:hover {
    color: white;
  }  

  .nav-menu {
    display: flex;
    list-style: none;
    margin-bottom: 0;
  }
  .nav-item {
    padding: 0 1rem;
    cursor: pointer;
    font-size: 24px;
  }
  .nav-links {
    color: #fff;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
    color: goldenrod;
  }  

  .nav-mobile-wrapper{
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    color: goldenrod;
    /* New Stuff */
    flex: 1;
    align-self: flex-end;
    padding: .5rem .5rem;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100vw;
    bottom: 0;
    justify-content: center;
    z-index: 7;
  }

  .nav-mobile-menu{
    display: flex;
    list-style: none;
    flex: 1;
    padding: .5rem .5rem;
    justify-content: space-around;
    margin-bottom: 0;
  }
  .nav-mobile-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
  }