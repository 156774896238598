.model-tiles-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 12px;
  padding: 20px;
  background-color: #1f1f1f;
  border-radius: 10px;
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.5);
}

.model-tile {
  padding: 1rem;
  background: linear-gradient(145deg, #3a3b3e, #252627);
  border-radius: 12px;
  font-size: 14px;
  color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.model-tile:hover {
  transform: translateY(-7px) scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
  background-color: #2d2d2d; /* Slight color change on hover */
}

.tile-header h5 {
  font-size: 14px;
  margin-bottom: 8px;
  color: #a7be6c;
  background-color: #2e2f31;
  padding: 8px 12px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.tile-content h6 {
  font-size: 14px;
  margin-top: 7px;
  margin-bottom: 8px;
  color: #444343;
  background-color: #d5d6d8;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.tile-header h5:hover {
  background-color: #414345;
  transform: translateY(-3px);
  cursor: pointer;
}

.tile-content h6:hover {
  font-size: 15px;
  color: #272727;
  background-color: #eeeeee;
  transform: translateY(-2px) scale(1.1);
  font-weight: bold;
  cursor: pointer;
}

.tile-header h4 {
  font-size: 12px;
  color: #a5a5a5;
  margin-bottom: 5px;
}

.tile-header h6 {
  font-size: 12px;
  color: #a5a5a5;
  margin-bottom: 10px;
}

.tile-content p {
  font-size: 14px;
  background-color: #2e2f31;
  color: #c6c7c6;
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.highlight-tile {
  border: 5px dotted #e5fca7;
  background-color: #3a3d42;
  color: #ffffff;
  box-shadow: 0 0 15px rgba(229, 252, 167, 0.7);
  transition: box-shadow 0.3s ease;
}

.highlight-tile:hover {
  box-shadow: 0 0 30px rgba(229, 252, 167, 0.9);
}

.date-highlight-0 {
  background: linear-gradient(145deg, #6a774b, #565f37);
}

.date-highlight-1 {
  background: linear-gradient(145deg, #535583, #434458);
}

.date-highlight-2 {
  background: linear-gradient(145deg, #4d7263, #415349);
}

.date-highlight-3 {
  background: linear-gradient(145deg, #7a5b6a, #553f4f);
}

.date-highlight-4 {
  background: linear-gradient(145deg, #586e53, #40523c);
}

.date-highlight-5 {
  background: linear-gradient(145deg, #556e6e, #3c524f);
}

.date-highlight-6 {
  background: linear-gradient(145deg, #6d5365, #523c4a);
}
