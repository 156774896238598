:root {
    --primary: white ;
}

.btn2 {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: TangerineSkies-Sans;
    margin: 10px;
}

.btn-primary {
    background-color: var(--primary);
    color: black;
    border: 1px solid var(--primary);
}

.btn-outline {
    color: white;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-medium {
    padding: 8px 20px;
    font-size: 14px;
    width: 150px;
    background-color: white;
    color: black;
    border-color: white;
}

.btn-large {
    padding: 12px 26px;
    font: 24px;
    background-color: white;
    color: black;
    border-color: white;
}

.btn-medium:hover, .btn-large:hover {
    background-color: goldenrod;
    color: black;
    transition: all 0.3s ease-out;
}