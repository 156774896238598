.containerHome {
    padding-right: 0;
    padding-left: 0;
    font-family: Apercu-Regular;
    background-color: #202124;
}

.headerRow {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../images/Muhamed-Ali.jpg') center top/cover no-repeat;
    height:75em;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start (top) */
    justify-content: flex-start; /* Align children to the top */
    border-top: 1px solid white;
    margin-left: 0;
    margin-right: 0;
    object-fit: contain;
}

.headerImage {
    width: 100px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space between the image and the text */
    margin-top: -15em;
}
.headerCol{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    color: white;
    font-size: 55px;
    letter-spacing: 1px;
}

.headerTitle {
    margin-top: -10em;
    flex-basis: 100%;
    text-align: center;
}
.headerBody {
    color: white;
    margin-top: -12em;
    font-size: 28px;
    font-family: Apercu-Regular;
    flex-basis: 100%;
    text-align: center;
}
.btn-mobile {
    color: white;
    display: flex;
    align-content: flex-start;
    
}
.btn-outline {
    color: black;
    padding: 8px 20px;
    border: 1px solid white;
    border-radius: 5px;
    transition: all 0.3s ease-out;
}
.headerBtns {
    display: flex;
    justify-content: center;
    margin-top: -3em;
}
.secondRow {
    height: 400px;
}
.appCol {
    display: flex;
    background: black;
    justify-content: center;
    align-items: flex-end;
}
.phoneDemo {
    padding-bottom: 20px;
    padding-top: 20px;
    width: 400px;
    height: 400px;
}
.phoneVideo {
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 200px;
    height: 400px;
}
.thirdRow {
    height: 400px;
}
.scrapeCol {
    background: #C2D4D9;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.thirdRowCont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.thirdRowHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
}
.thirdRowDetails {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
}
.scrapeIMG {
    /* Ratio is 2.3 */
    width: 460px;
    height: 200px;
    border: 5px solid black;
}
.forthRow{
    height: 400px;
}
.featuresCol {
    background: black;
}
.howitworksIMG {
    /* 1.75 ratio */
    width: 525px;
    height: 300px;
    border: 5px solid black;

}
.nomargin {
    margin-bottom: 0;
}
.small {
    font-family: Apercu;
    font-size: 10px;
    letter-spacing: 0px;
}
.padLeft {
    padding-left: 50px;
}


/* GENERIC HEADER COMPONENT */
.panel{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.panelContainer {
    width: 100%;
    padding: 10px 20px 10px 20px;
}
.panelTitle {
    color: black;
    font-size: 32px;
    letter-spacing: 3px;
}
.panelBody {
    color: black;
    font-family: Apercu;
    font-size: 18px;
    letter-spacing: 0px;
}
.panelBtn{
    width: 175px;
}
.black{
    background: black;
}
.rust{
    background: #D1664A;
}
.gold{
    background: #E0C74F;
}
.blue{
    background: #C2D4D9;
}
.forest{
    background: #33534B;
}
.grey{
    background: #E8E8E8;
}

html, body{
    overflow-x: hidden;
}

@media screen and (max-width: 768px) {
    .scrapeIMG {
        width: 0;
        height: 0;
        border: 0px;
    }
    .howitworksIMG {
        width: 0;
        height: 0;
        border: 0px;
    }
    .hideMobile{
        width: 0;
        height: 0;
        border: 0px;
    }
}