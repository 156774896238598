.bet-container {
    display: grid;
    gap: 20px; /* Spacing between bet tiles */
    margin-bottom: 20px; /* Optional margin */
    margin-left: 20px; /* Optional margin */
    margin-right: 20px; /* Optional margin */
    grid-template-columns: repeat(3, 1fr); /* Three columns */
  
    /* Adjust for mobile */
    @media (max-width: 640px) {
      grid-template-columns: repeat(1, 1fr); /* One column on mobile */
      padding-bottom: 80px;
    }
  }
  
.bet-container-mobile {
  display: grid;
  gap: 20px; /* Spacing between bet tiles */
  margin-bottom: 20px; /* Optional margin */
  margin-left: 20px; /* Optional margin */
  margin-right: 20px; /* Optional margin */
  grid-template-columns: repeat(1, 1fr); /* One column on mobile */
  padding-bottom: 80px;
}