.bets-page {
  /* This is the Background Color for the whole app */
  background-color: #202124;
  /* Tile Color*/
  color: white;
}
.betRow {
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
}
.betRowSummary {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 18px;
}

.d-flex {
  justify-content: space-around;
}

.d-flex-mobile {
  justify-content: space-around;
  flex-direction: column;
  font-size: 10px;
}

@media (max-width: 768px) {
  .betRow {
      font-family: 'Helvetica', 'Arial', sans-serif;
      font-size: 6px;
    }
  }
  @media (max-width: 768px) {
    .betRowSummary {
        font-family: 'Helvetica', 'Arial', sans-serif;
        font-size: 10px;
      }
    }
@media (max-width: 768px) {
    .betHeader {
        font-family: 'Helvetica', 'Arial', sans-serif;
        font-size: 6px;
    }
}
@media (max-width: 768px) {
  .betHeaderSummary {
      font-family: 'Helvetica', 'Arial', sans-serif;
      font-size: medium;
  }
}