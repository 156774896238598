.score-tile {
    gap: 20px; /* Spacing between bet tiles */
    background-color: #303134;
    border-radius: 8px; /* Adjust the border-radius value as needed */
    padding: 10px; /* Add space around the interior content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Small shadow */
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: small;
  }

.score-row{
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: small;
}