@font-face {
    src: local('TangerineSkies'), url('./fonts/TangerineSkies/TangerineSkiesScript-Regular.ttf') format('truetype');
    font-family: 'TangerineSkies-Script';
}
@font-face {
    src: local('TangerineSkies'), url('./fonts/TangerineSkies/TangerineSkiesSans-Regular.ttf') format('truetype');
    font-family: 'TangerineSkies-Sans';
}
@font-face {
    src: local('Apercu'), url('./fonts/apercu/apercu-regular.otf') format('woff');
    font-family: 'Apercu-Regular';
}
.root {
    display: flex;
    background-color: #202124;
    justify-content: space-between;
}
.headerText {
    background-color: black;
    color: goldenrod;
    margin-bottom: 0;
    padding: .5rem;
}
body {
    margin: 0;
    padding: 0;
    background-color: #202124; /* Set the desired background color */
}