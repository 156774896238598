.bet-tile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px; /* Spacing between bet tiles */
    background-color: #303134;
    border-radius: 8px; /* Adjust the border-radius value as needed */
    padding: 10px; /* Add space around the interior content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Small shadow */
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: medium;
  }
  
  .bet-info {
    display: flex;
    gap: 20px; /* Spacing between bet tiles */
  }
  
  .bet-status {
    display: 'flex';
    align-items: 'center';
  }
  
  .bet-team {
    font-weight: bold;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: medium;
  }
  
  .bet-spread {
    font-size: 14px;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: small;
  }
  
  .bet-date {
    /* font-style: italic; */
    font-size: 12px;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: small;
  }
  .bet-result {
    display: flex;
    align-self: flex-end;
    padding-right: 1rem;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: small;
  }