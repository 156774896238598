.col-header{
    padding: 2rem;
    padding-bottom: .5rem;
    color: goldenrod;
}

.kpi-tiles{
    display: grid;
    gap: 20px; /* Spacing between bet tiles */
    padding: 1rem;
    padding-top: 0;
    grid-template-columns: repeat(5, 1fr); /* Three columns */
    text-align: center;
}

.col-header-mobile{
    padding-bottom: .5rem;
    color: goldenrod;
    font-size: 1rem;
    padding: .5rem;
    padding-left: 1rem;
}

.kpi-tiles-mobile{
    display: grid;
    gap: 20px; /* Spacing between bet tiles */
    padding: 1rem;
    padding-top: 0;
    grid-template-columns: repeat(2, 1fr); /* One column on mobile */
    text-align: center;
}

.kpi-tile{
    padding: 1rem;
    background-color: #303134;
    line-height: 1.15;
}

.kpi-label {
    font-size: .9rem;
}

.kpi-value {
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 1.5rem;
}

.kpi-accent {
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: .9rem;
}