.graph-tile{
    padding: 1rem;
    background-color: #303134;
    line-height: 1.15;
    width: 100%;
    height: 200px;
}
.graph-container{
    display: grid;
    gap: 20px;
    padding: 1rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    @media (max-width: 640px) {
        grid-template-columns: 1fr;
        margin-bottom: 5rem;
    }
}

.graph-container-mobile{
    display: grid;
    gap: 20px;
    padding: 1rem;
    grid-template-columns: 1fr;
    align-items: center;
    margin-bottom: 5rem;
}