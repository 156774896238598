.bets-page {
    /* This is the Background Color for the whole app */
    background-color: #202124;
    /* Tile Color*/
    color: white;
}
.bet-home{
    /* flex: auto; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.bet-header {
    padding: 20px;
    color: goldenrod;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.col-header-seconday {
    color: white;
    opacity: .65;
    padding: 20px;
    text-align: end;
    @media (max-width: 640px) {
        font-size: .75rem;
        padding: .5rem;
    }
}
