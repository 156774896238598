.footer {
    height: 80px;
    background: black;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-top: 1px solid white;
}
.nomargin {
    margin-bottom: 0;
}
.small {
    font-family: Apercu;
    font-size: 10px;
    letter-spacing: 0px;
}
.socialIcons {
    height: 30px;
    width: 30px;
    margin: 5px;
}
.sixthRow {
    height: 40px;
    background: black;
    color: white;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-top: 1px solid white;
}

/* xs */
@media screen and (max-width: 576px) {
    .footer {
        height: 200px;
    }
    .footer {
        align-content: stretch;
    }
    
}