.bet_form {
    padding: 1rem;
    font-size: 12px;
}
.input-group-text {
    font-size: .75rem;
}

@media screen and (max-width: 576px) {
    .bet_form {
        padding: 1rem;
        margin-bottom: 8rem;
    }
}