.investorRow {
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: small;
}
.investorRowSummary {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: medium;
}

.filters2 {
  display: flex;
  justify-content: space-around;
  color: white;
}

@media (max-width: 768px) {
  .investorRow {
      font-family: 'Helvetica', 'Arial', sans-serif;
      font-size: 12px;
    }
  }
  @media (max-width: 768px) {
    .investorRowSummary {
        font-family: 'Helvetica', 'Arial', sans-serif;
        font-size: medium;
      }
    }
@media (max-width: 768px) {
    .investorHeader {
        font-family: 'Helvetica', 'Arial', sans-serif;
        font-size: 12px;
    }
}
@media (max-width: 768px) {
  .investorHeaderSummary {
      font-family: 'Helvetica', 'Arial', sans-serif;
      font-size: medium;
  }
}