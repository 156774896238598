.bets-page {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  margin-bottom: 100px;
}

.bet-header {
  font-size: 1.3em;
  color: goldenrod;
  background-color: #101820;
  margin-bottom: 0px;
  width: 100%;
  max-width: 1400px;
  text-align: left;
  border: 1px solid #5884b1;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 750px) {
  .bet-header {
    font-size: 0.7em; /* Shrinks the font size to 1em on mobile */
  }}

.grid-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 96%;
  max-width: 1400px;
  margin: 0 auto;
}

.grid-item-container {
  display: flex;
  flex-direction: column;
  /* background-color: #101820; */
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  width: 98%;
  margin: 0 auto;
  transition: all 0.3s ease;
}

.match-container {
  display: flex;
  justify-content: space-between;
  padding-left: 0px; /* Adjusts the left padding */
  padding-right: 5px; /* Keeps right padding the same */
  padding-top: 7px; /* Keeps top padding the same */
  padding-bottom: 7px; /* Keeps bottom padding the same */
  cursor: pointer;
}

@media (max-width: 750px) {
  .match-container {
    font-size: 0.7em;
  }}

.time {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ml {
  color: #adadad;
  justify-content: space-around;
}

.score-display {
  font-size: 3.2em;
  color: #c7c7b1;
  font-weight: bold;
}

.projections {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  gap: 10px;
  flex: 1;
}

.actual-scores {
  color: #c7c7b1;
}
.teams {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 50%;
  justify-content: center;
}

.home-team, .away-team {
  font-size: 1.1em;
}

.expanded-stats {
  display: flex;
  flex-direction: columns;
  justify-content: space-evenly;
  align-items: baseline;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.team-expanded-stats {
  text-align: center;
}
@media (max-width: 750px) {
  .team-expanded-stats {
    font-size: 0.7em;
  }}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin-top: 5px;
}

.progress-bar {
  height: 100%;
  background-color: limegreen;
  transition: width 0.3s ease;
}

.team-expanded-header {
  text-align: center;
  padding-bottom: 0;
}
@media (max-width: 750px) {
  .team-expanded-header {
    font-size: 0.7em;
  }}

.gold-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: gold;
  border-radius: 50%;
}
.green-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
}
.red-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}
.values {
  display: flex;
  color: #adadad;
  display: inline-block;
  justify-content: space-around;
}

.calendar-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.calendar-header h3{
  margin-right: 5px;
  @media (max-width: 750px) {
    font-size: 1.1em; /* Shrinks the font size to 1em on mobile */
  }
}
.calendar-header button {
  background-color: #496b8f; /* Blue background */
  color: white;             /* White text */
  border: none;             /* No border */
  padding: 4px 8px;        /* Padding for button size */
  font-size: 14px;          /* Larger font size for arrows */
  cursor: pointer;          /* Pointer cursor on hover */
  border-radius: 4px;       /* Rounded corners */
  transition: background-color 0.2s ease;
  margin-right: 5px;
  margin-left: 5px;
}

.date-picker {
  text-align: center;
  display: flex;
  position: relative;
  border: 1px solid gray;
  padding: 5px;
  border-radius: 10px;
  gap: 10px;
}
.date-picker:hover {
  cursor: pointer;
}

.loader {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
  margin: 12px auto;
  box-shadow: 0 0 15px rgba(52, 152, 219, 0.4);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 750px) {
  .loader {
    width: 20px;
    height: 20px;
  }}

.team-logo {
  width: 25px;  /* Set the size of the logo */
  height: 25px; /* Ensure it remains square */
  margin-right: 10px; /* Space between logo and team name */
  margin-top: 2px; /* Space between logo and team name */
  margin-bottom: 2px; /* Space between logo and team name */
}
@media (max-width: 750px) {
  .team-logo {
    width: 20px;  /* Set the size of the logo */
    height: 20px; /* Ensure it remains square */
  }}
.team-logo2 {
  width: 80px;  /* Set the size of the logo */
  height: 80px; /* Ensure it remains square */
  margin-right: 10px; /* Space between logo and team name */
  margin-top: 2px; /* Space between logo and team name */
  margin-bottom: 2px; /* Space between logo and team name */
}
@media (max-width: 750px) {
  .team-logo2 {
    width: 55px;  /* Set the size of the logo */
    height: 55px; /* Ensure it remains square */
  }}
  