.league-group {
    display: grid;
    grid-template-columns: repeat(1, .5fr); /* Three columns */
    gap: 20px; /* Spacing between bet tiles */
    margin-bottom: 1rem;
}
.league-group-mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    gap: 2px; /* Spacing between bet tiles */
    margin-bottom: 1rem;
}
.league-button-mobile {
    margin: 1px;
}

.leaguetext{
    font-size: 10px;
}

.leaguetext-mobile{
    font-size: 5px;
}